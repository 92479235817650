*{
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

p{
  color:#444;
  font-family: 'Roboto', sans-serif;
}

h1{
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

body{
  font-family: 'Raleway', sans-serif;
}
.card {
margin: 20px 0;
box-sizing: border-box;
  /* padding: 10px; */
}

.bookingContainer{
  width: calc(100% - 40);
  /* height: 374px; */
  padding: 0 20px;
  overflow: auto;
  margin: 0 auto;
}

.form{
  width: 900px;
  margin: 0 auto;
}

.dflex{
  display: flex;
}
.container{
  margin-top: 50px;
  max-width: 1000px;
  width: 100%;
  /* height: 500px; */
  margin: 0 auto;
  min-height: 500px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.nav-button{
  background-color: #F15D26;
  border:none;
  color: white;
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 0;
  cursor: pointer;
}

.datepicker-zion{
  display: flex;
  align-items: 'center';
  padding:20px
}

.divider{
  display: block;
  width: 100%;
  height: 0.5px;
  background-color: #bbbbbb;
}

.label{
  font-weight: 700;
  font-size: 14px;
  color:#333
}

button.primary{
  background-color: #F15D26;
  border:none;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
}

.bookingImage{
  position: relative;
}

.bookingImage span{
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #fff;
  color:green;
  padding: 10px;
  z-index: 10;
}

.bookingContent{
  margin-left: 30px;
  position: relative;
}

.bookingContent h1{
  color: #666;
  padding: 0;
  font-size: 24px;
  margin: 5px 0;
}

.bookingContent p{
  margin-top: 10px;
  font-size: 12px;
  
}

.bookingContent h2{
  padding: 0;
  margin: 5px 0;
  font-weight: 700;
  color:  #F15D26;;
}

.bookingContent button.booknow{
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.bookingInput{
  border: 1px solid #999;
  border-radius: 5px;
  padding:5px;
  font-size: 14px;
  width: 100%;
}


.heading{
  color:#333
}
.heading::after{
  content: '';
  width: 50px;
  height: 2px;
  margin-top: 5px;
  background-color: #F15D26;
  display: block;
}


.img-fluid{
  width: 100%;
  max-width: 100%;
}

.react-datepicker__input-container input{
  border:none;
  border:1px solid #999;
  padding:10px;
  border-radius: 10px;
  margin-top: 5px;
}


.home-input{
  border:none;
  border:1px solid #999;
  padding:10px;
  border-radius: 10px;
  margin-top: 5px;
  width: 100%;
}

.datepicker input{
  width: 100%;
}

.content p{
  line-height: 1.5;
}

.content li{
  line-height: 1.5;
}

.intractionBlock{
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
}

.bookingBox{
  width: 100%!important;
}
.mainPage{
  flex-direction:column-reverse;
}
.content{
  margin-top: 30px;
  width: 100%!important;
}
.contact-details{
  flex-direction: column;
}
.bookingContent h1{
  font-size: 16px!important;
}
.bookingContent h2{
  font-size: 14px!important;
}
.bookingContent h2 span{
  font-size: 12px!important;
}
.bookingContent p{
  font-size: 12px!important;
}
.bookingContent .dflex{
  height: 20px;
  font-size: 12px!important;
  margin-top: 10px;
}
.bookingContent .dflex .adultIcon{
  width: 20px;
}
.bookingContent .dflex .kidIcon{
  width: 10px;
}
.bookingContent button.booknow{
  font-size: 14px!important;
}

.bookingContainer .dflex{ 
  width: 100%;
  flex-direction: column;
  
}

.bookingContainer div{
  margin-left: 0!important;
}

.bookingContainer .dflex input{
  margin-left: 0!important;
  width: 100%!important;
}
.paymentsummary{
  align-items: center!important;
  justify-content: center!important;
  width: 100%!important;
}

.paxicons{
  align-items: flex-end;
  flex-direction: row!important;
}

.inputwrapper{
  width: 100%!important;
}

.mobilefullwidth{
  width: 100%!important;
 
}

.mobilerowflex{
  flex-direction: row!important;
}

.footer-text{
  font-size: 14px;
}

@media screen and (min-width: 480px) {
  .bookingBox{
    width: 100%!important;
  }
  .mainPage{
    flex-direction:column-reverse;
  }
  .content{
    margin-top: 30px;
    width: 100%!important;
  }
}

@media screen and (min-width: 1050px) {
  .footer-text{
    font-size: 16px;
  }
  .inputwrapper{
    width: 50%!important;
  }
  .mobilerowflex{
    flex-direction: row!important;
  }

  .paxicons{
    align-items: flex-end;
  }

  .bookingBox{
    width: 45%!important;
  }
  .mainPage{
    flex-direction:row;
  }
  .content{
    margin-top: 0;
    width: 55%!important;
  }
  .home-input{
    border:none;
    border:1px solid #999;
    padding:10px;
    border-radius: 10px;
    margin-top: 5px;
    width: 161px;
  }
  .contact-details{
    flex-direction: row;
  }

  .bookingContent{
    margin-left: 30px;
    position: relative;
  }
  
  .bookingContent h1{
    color: #666;
    padding: 0;
    font-size: 24px!important;
    margin: 5px 0;
  }
  
  .bookingContent p{
    margin-top: 10px;
    font-size: 12px!important;
    
  }
  
  .bookingContent h2{
    padding: 0;
    font-size: 20px!important;
    margin: 5px 0;
    font-weight: 700;
    color:  #F15D26;;
  }
  .bookingContent .dflex{
    margin-top: 20px!important;
  }
  .bookingContent .dflex .adultIcon{
    width: 30px!important;
  }
  .bookingContent .dflex .kidIcon{
    width: 15px!important;
  }
  .bookingContent button.booknow{
    font-size: 24px!important;
  }

  .bookingContainer .dflex{ 
    width: 100%;
    flex-direction: row;
    gap: 10px;
  }
  
  .paymentsummary{
    flex-direction: column;
    align-items: center!important;
    justify-content: center!important;
    width: 100%!important;
  }

  
  
  
}